import React from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
import { useSelector } from 'react-redux';

var CanvasJSChart = CanvasJSReact.CanvasJSChart;
const PieChart = ({
  title = 'HRA by Carriers',
  type = 'pie',
  dataPoints = [{ y: 0, label: '' }],
}) => {
  const { theme } = useSelector((state) => state?.theme);
  let isDark = theme === 'black';

  const options = {
    animationEnabled: true,
    title: {
      text: title,
      fontSize: 20,
      fontColor: isDark ? '#c7cbd3' : '#28303f',
    },
    backgroundColor: isDark ? '#161a23' : '#ffffff',
    legend: {
      fontColor: isDark ? '#c7cbd3' : '#28303f',
    },
    data: [
      {
        type,
        startAngle: 75,
        toolTipContent: '<b>{label}</b>: {y}',
        showInLegend: 'true',
        legendText: '{label}',
        indexLabel: '{label} - {y}',
        indexLabelFontSize: 16,
        indexLabelFontColor: isDark ? '#c7cbd3' : '#28303f',
        dataPoints,
      },
    ],
  };
  return (
    <div>
      <CanvasJSChart options={options} />
    </div>
  );
};

export default PieChart;
