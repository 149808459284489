import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import FormInput from '../../../components/FormInput';
import PhoneInput from 'react-phone-number-input/react-hook-form';
import { schema } from '../constants';
import { getObjectLength, submitBtnTxt } from '../../../helpers/functions';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { inviteAgent, inviteAgentUpdate } from '../../../api';
import { useAlert } from '../../../hooks/useAlert';
import CancelButton from '../../../components/Button/CancelButton';
import SubmitButton from '../../../components/Button/SubmitButton';
import { useTranslation } from 'react-i18next';

const initialValues = {
  firstName: '',
  middleName: '',
  lastName: '',
  email: '',
  phone: '',
  npn: '',
};

const SendInvites = ({ handleClose = () => {}, rowData = {} }) => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const { showAlert } = useAlert();
  let isEdit = getObjectLength(rowData);

  const {
    setValue,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
    mode: 'onSubmit',
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: isEdit ? inviteAgentUpdate : inviteAgent,
    mutationKey: isEdit ? 'inviteAgentUpdate' : 'inviteAgent',
    onSuccess: ({ data }) => {
      showAlert({ type: 'success', message: data?.data?.message });
      queryClient.invalidateQueries({ queryKey: ['getAgentInviteList'] });
      handleClose();
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });

  const onSubmit = ({ npn, ...rest }) => {
    mutate({ NPN: npn, ...rest, uuid: isEdit ? rowData?._id : undefined });
  };

  useEffect(() => {
    if (isEdit) {
      const { agentDetail: { NPN, ...rest } = {} } = rowData || {};
      reset({
        npn: NPN,
        ...rest,
      });
    }
  }, [rowData]);

  return (
    <form
      className="w--full h--full"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      <div className="overflow--auto dialogScroll d--flex flex--column gap--md p--md">
        <div className="w--full orText border-top--black-200 m-t--sm d--flex align-items--center justify-content--center position--relative">
          <span
            className={`w-min--60 font--sm p--md bg--white d--flex justify-content--center align-items--center mn--top-20 c--pointer text--primary`}
          >
            {t('translation:title.AgentInformation')}
          </span>
        </div>
        <div className="d--flex gap--md">
          <div className="w--full">
            <Controller
              name="firstName"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  type="text"
                  label="First Name"
                  maxLength={30}
                  error={errors?.firstName?.message}
                />
              )}
            />
          </div>
          <div className="w--full">
            <Controller
              name="middleName"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  type="text"
                  label="Middle Name"
                  error={errors?.middleName?.message}
                  height="40"
                  required={false}
                />
              )}
            />
          </div>
        </div>
        <div className="d--flex gap--md">
          <div className="w--full">
            <Controller
              name="lastName"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  required={false}
                  type="text"
                  label="Last Name"
                  maxLength={30}
                  error={errors?.lastName?.message}
                />
              )}
            />
          </div>
          <div className="w--full">
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <FormInput
                  {...field}
                  type="text"
                  label="Email"
                  error={errors?.email?.message}
                  onChange={(e) => {
                    setValue('email', e.target.value?.trim(), {
                      shouldValidate: true,
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.code === 'Space') {
                      e.preventDefault();
                    }
                  }}
                />
              )}
            />
          </div>
        </div>

        <div className="d--flex gap--md">
          <div className="w--full">
            <label
              className={`label--control font--sm font--500 m-b--sm d--inline-block ${
                errors?.phone?.message ? 'text--danger' : 'text--grey'
              }`}
            >
              {errors?.phone?.message
                ? t(`translation:errors.${errors?.phone?.message}`)
                : t(`translation:labels.Phone`)}
              <span className=" font--sm text--danger ">*</span>
            </label>
            <PhoneInput
              name="phone"
              control={control}
              defaultCountry={'US'}
              international={true}
              withCountryCallingCode={true}
              internationalIcon={() => (
                <span>
                  <i className="fa fa-phone icon-circle bg-danger"></i>
                </span>
              )}
              limitMaxLength={true}
              className={`form--control w--full h-min--36 radius--sm p-l--md p-r--md  d--flex align-items--center ${
                errors?.phone?.message
                  ? 'border-full--danger'
                  : 'border-full--black-200'
              }`}
              onChange={(phone) =>
                setValue(`phone`, phone, {
                  shouldValidate: true,
                })
              }
              // onCountryChange={(_) => {
              //   setValue('country', _);
              // }}
            />
          </div>
          <div className="w--full">
            <Controller
              name="npn"
              control={control}
              render={({ field }) => {
                const { onChange } = field;
                return (
                  <FormInput
                    {...field}
                    onChange={(e) => {
                      if (`${e.target.value}`?.length <= 12) {
                        onChange(e.target.value);
                      } else {
                        return;
                      }
                    }}
                    type="number"
                    label="NPN"
                    // placeholder="Enter npn"
                    maxLength="12"
                    error={errors?.npn?.message}
                    onKeyDown={(e) => {
                      if (['e', '+', '-'].includes(e.key)) e.preventDefault();
                    }}
                    disabled={isEdit}
                  />
                );
              }}
            />
          </div>
        </div>
      </div>
      <div className="d--flex justify-content--between gap--md p-l--md p-r--md p-t--md border-top--primary-100">
        <CancelButton onClick={handleClose}>
          {t(`translation:button.Cancel`)}
        </CancelButton>
        <SubmitButton disabled={isLoading}>
          {submitBtnTxt(
            isLoading,
            isEdit
              ? t(`translation:button.Update`)
              : t(`translation:button.Invite`),
          )}
        </SubmitButton>
      </div>
    </form>
  );
};

export default SendInvites;
