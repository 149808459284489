import React, { useState } from 'react';
import FormInput from '../../components/FormInput';
import Button from '../../components/Button';
import Congratulations from '../../assets/images/party-popper.png';
import { useNavigate } from 'react-router-dom';
import OTPInput from 'react-otp-input';
import OtpTimer from './OtpTimer';
import { useMutation } from '@tanstack/react-query';
import { resendOtp, verifyOtp } from '../SignUp/actions';
import { submitBtnTxt } from '../../helpers/functions';
import { useAlert } from '../../hooks/useAlert';
import useIcons from '../../assets/icons/useIcons';
import SubmitButton from '../../components/Button/SubmitButton';
import { MEMBER } from '../../helpers/constants';
import CongratsScreen from '../SignUp/Components/CongratsScreen';
import { useTranslation } from 'react-i18next';

const OTPVerify = ({ formData, setShowOtp, userType }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { BackRoundIcon } = useIcons();
  const [time, setTime] = useState(180);
  const { showAlert } = useAlert();

  // const [isFinished, setIsFinished] = useState(true);
  const [isFinished, setIsFinished] = useState(false);
  const [otp, setOtp] = useState(false);
  const [responseData, setResponseData] = useState(false);

  const { mutate, isLoading } = useMutation({
    mutationFn: verifyOtp,
    onSuccess: ({ data }) => {
      setIsFinished(true);
      setResponseData(data?.data);
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });
  const { mutate: resendMutate, isLoading: resendLoading } = useMutation({
    mutationFn: resendOtp,
    onSuccess: ({ data }) => {
      showAlert({ type: 'success', message: data?.data?.message });
      setTime(180);
    },
    onError: (error) => {
      const errMsg = error?.message ?? 'unexpected error';
      showAlert({ type: 'danger', message: errMsg });
    },
  });
  // success screen start
  if (isFinished) {
    return (
      <div className="w--full d--flex flex--column gap--sm radius--md  w-max--600 w--full bg--secondary p--xl z-index--xs bg--white box--shadow d--flex align-items--center justify-content--center flex--column gap--xs h-min--300">
        {userType === MEMBER ? (
          <CongratsScreen {...{ responseData }} />
        ) : (
          <div className="w--full d--flex align-items--center justify-content--center flex--column">
            <img
              src={Congratulations}
              alt="..."
              className="w--full w-max--100 m-b--xl"
            />
            <h2 className="font--xl text--c m-b--md text--success">
              {t('translation:commonWords.Congratulations')}
            </h2>
            <h3 className="font--lg text--c m-b--md ">
              {t('translation:message.AccountCreated')}
            </h3>
            <h5 className="font--sm font--400 text--c m-b--md">
              {t('translation:message.CredentialsOnEmail')}
            </h5>
            <Button
              type="button"
              btnClasses="btn w-max--120"
              size="md"
              variant="primary"
              color="white"
              onClick={() => navigate('/')}
            >
              {t('translation:button.Login')}
            </Button>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="d--flex flex--column gap--sm w--full d--flex justify-content--start align-items--center gap--lg position--relative">
      <div className="d--flex w--full w-max--600   m-b--md">
        <div
          onClick={() => setShowOtp(false)}
          className="d--flex align-items--center gap--xs text--black c--pointer font--sm "
        >
          <span className="d--flex">
            <BackRoundIcon width={25} height={25} />
          </span>
          <span className="font--500">{t('translation:button.Back')}</span>
        </div>
      </div>
      <div className="d--flex w--full flex--column w--full w-max--600 gap--lg">
        <h3 className="text--c m-b--md font--md font--600">
          {t('translation:message.EmailSent')}
          {'  '}
          <span className="text--primary">{formData?.email} </span>
          {t('translation:message.WithVerificationCode')}
        </h3>
        <form
          className="w--full d--flex flex--column gap--sm radius--md  w-max--600 w--full bg--secondary p--xl z-index--xs bg--white box--shadow d--flex align-items--center justify-content--center flex--column gap--xs h-min--300"
          autoComplete="off"
          onSubmit={(e) => {
            const { message, ...rest } = formData || {};
            e.preventDefault();
            mutate({ ...rest, otp: parseInt(otp) });
          }}
        >
          <h3 className="text--c m-b--md font--lg font--600">
            {t('translation:message.EnterVerificationCodeBelow')}
          </h3>
          {/* <h3 className="text--c m-b--md font--lg font--600">OTP Verification</h3> */}
          <div className="d--flex justify-content--center align-items--center gap--md  m-x--auto out m-b--sm ">
            <OTPInput
              value={otp}
              onChange={setOtp}
              inputType="number"
              numInputs={4}
              renderInput={(props) => (
                <FormInput
                  type="number"
                  extraClasses="otp-input-control"
                  {...props}
                />
              )}
              shouldAutoFocus
              containerStyle={{ gap: '10px' }}
              inputStyle={{ fontSize: '16px', width: '4em' }}
            />
          </div>

          <div className="d--flex align-items--center justify-content--center gap--sm w--full m-t--sm">
            <SubmitButton
              btnClasses="btn w-max--170"
              disabled={isLoading || otp.length !== 4}
              size="md"
            >
              {submitBtnTxt(isLoading, t('translation:button.Verify'))}
            </SubmitButton>
          </div>

          <div className="w--full d--flex align-items--center justify-content--center gap--sm font--sm m-t--lg">
            {time > 0 ? (
              <>
                {t('translation:message.ResendOtp')}{' '}
                <OtpTimer {...{ time, setTime, timeInSec: true }} /> sec
              </>
            ) : (
              <span
                className="c--pointer text--primary font--500"
                onClick={() => {
                  const {
                    firstName,
                    lastName,
                    email,
                    phone,
                    country,
                    userType,
                  } = formData || {};

                  if (!resendLoading) {
                    resendMutate({
                      firstName,
                      lastName,
                      email,
                      phone,
                      country,
                      userType,
                    });
                  }
                }}
              >
                {t('translation:button.Resend')}
              </span>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default OTPVerify;
