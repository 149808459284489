import React, { useRef, useState } from 'react';
import FormInput from '../../FormInput';
import html2canvas from 'html2canvas';
import Jost from '../../../assets/images/Jost.png';
import Playwrite from '../../../assets/images/Playwrite.png';
import Grey from '../../../assets/images/Grey.png';
import Edu from '../../../assets/images/Edu.png';
import Button from '../../Button';
import { toast } from 'react-toastify';

const textArray = [
  {
    id: 1,
    name: '"Jost", sans-serif',
    label: 'Jost',
    image: Jost,
  },
  {
    id: 2,
    name: '"Playwrite AR", sans-serif',
    label: 'Playwrite',
    image: Playwrite,
  },
  {
    id: 3,
    name: '"Grey Qo", sans-serif',
    label: 'Grey',
    image: Grey,
  },
  {
    id: 4,
    name: '"Edu AU VIC WA NT Hand", sans-serif',
    label: 'Edu',
    image: Edu,
  },
];

const Creative = ({ handleChange = () => {} }) => {
  const [font, setFont] = useState();
  const signatureRef = useRef(null);
  const [signName, setSignName] = useState('');

  function handleNameChange(e) {
    const name = e.target.value;
    setSignName(name);
  }

  async function handleConvertToImage() {
    if (signatureRef.current) {
      if (!signName) {
        return toast.error('Please Sign the document.');
      }

      const canvas = await html2canvas(signatureRef.current);
      const imgData = canvas.toDataURL('image/png');
      handleChange(null, imgData);
    }
  }

  return (
    <div className="d--flex gap--sm p--sm bg--white flex--column">
      <div className="d--flex gap--sm w--full">
        <div className="bg--white d--flex flex--column w--full gap--sm ">
          <FormInput
            name="sign"
            onChange={handleNameChange}
            value={signName}
            placeholder="Signature"
          />
          <div
            style={{
              border: '4px dashed #eee',
              height: 150,
              fontSize: 50,
              overflow: 'auto',
            }}
          >
            <div
              ref={signatureRef}
              style={{
                width: '100%',
                height: '100%',
                fontFamily: font?.name,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {signName}
            </div>
          </div>
          <div className="d--flex justify-content--center w--full gap--sm">
            <Button
              onClick={handleConvertToImage}
              type="button"
              variant="primary"
              color="black"
              isOutline
              borderColor="primary"
              btnClasses="btn w-max--150"
              size="sm"
            >
              Submit
            </Button>
          </div>
        </div>
        <div className="h-max--250 overflow--auto  d--flex flex--column gap--xs p-r--xs">
          {textArray.map((text) => {
            return (
              <div
                className=" radius--sm c--pointer"
                key={text.id}
                onClick={() => setFont(text)}
              >
                <div
                  className={`w--full  w-max--100 d--flex align-items--center justify-content--center ${
                    font?.id === text?.id ? 'bg--primary-200' : ''
                  }  p--xs`}
                >
                  <img
                    className="object--fit w--full  "
                    src={text.image}
                    alt={text.label}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Creative;
