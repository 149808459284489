import React, { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';

// constants
import { AGENCY_USER, AGENT, CARRIER, MEMBER } from '../helpers/constants';

// Error pages
import Error500Page from '../pages/Error500Page';
import PageNotFound from '../pages/PageNotFound';
import Unauthorized from '../pages/Unauthorized';

// public routes
import Login from '../pages/Login';
import ForgotPassword from '../pages/ForgotPassword';

// protected routes
import Dashboard from '../pages/Dashboard';
import Members from '../pages/Members';
import Agents from '../pages/Agents';
import Agency from '../pages/Agency';
import Settings from '../pages/Settings';
import MyAccount from '../pages/MyAccount';

// auth related components
import Access from '../auth/Access';

import CreateTemplate from '../pages/CreateTemplate';
import ViewSignedPDF from '../pages/ViewSignedPDF';
import Claims from '../pages/Claims';
import Invitation from '../pages/Invitation';
import RecieveInvitation from '../pages/RecieveInvitation';
import { ChatPage } from '../pages/Chat';
import SignUp from '../pages/SignUp';
import ChatArea from '../pages/Chat/ChatArea';
import Verify from '../pages/Verify';
import { EligibilityPdf } from '../pages/Pdf/EligibilityPdf';
import DocumentLayout from '../layouts/DocumentLayout';
import PreviewDocument from '../components/PDFForms/PreviewDocument';
import PreviewDocumentReadMode from '../components/PDFForms/PreviewDocumentReadMode';
import PreviewDocumentSignMode from '../components/PDFForms/PreviewDocumentSignMode';
import AgentKpi from '../pages/AgentKpi';
import Verification from '../pages/Verification';
import PreviewDocumentSignReadMode from '../components/PDFForms/PreviewDocumentSignReadMode';
import Configuration from '../pages/Configuration';
import EmailIntegration from '../pages/MyAccount/Components/EmailIntegration';
import InboundWebhook from '../pages/MyAccount/Components/InboundWebhook';
import Storage from '../pages/Storage';
import PlanPricing from '../pages/PlanPricing';
import ConsentWebhook from '../pages/Configuration/Components/AgencyAgentConfiguration/ConsentWebhook';
import ConsentConsumerData from '../pages/Configuration/Components/CarrierConfiguration/ConsentConsumerData';
import FetchAgentRecord from '../pages/Configuration/Components/CarrierConfiguration/FetchAgentRecord';
import GenerateKey from '../pages/Configuration/Components/GenerateKey';
import ClaimWebhook from '../pages/Configuration/Components/CarrierConfiguration/ClaimWebhook';
import ConsentCheck from '../pages/Configuration/Components/CarrierConfiguration/ConsentCheck';
import HRA from '../pages/HRA';
import PreviewDocumentSignModeOuter from '../components/PDFForms/PreviewDocumentSignModeOuter';
import HRAStep3 from '../pages/HRA/components/HRAStep3';
import HRAConsentSignMode from '../components/PDFForms/HRAConsent/HRAConsentSignMode';
import HRAAggrementSignMode from '../components/PDFForms/HRAAgreement/HRAAggrementSignMode';
import HRAConsentPreviewDocument from '../components/PDFForms/HRAConsent/HRAConsentPreviewDocument';
import HRAAgreementPreviewDocument from '../components/PDFForms/HRAAgreement/HRAAgreementPreviewDocument';
import HRAFormSignMode from '../components/PDFForms/HRAFORM/HRAFormSignMode';
import HRAFormPreviewDocument from '../components/PDFForms/HRAFORM/HRAFormPreviewDocument';
import LoginByAccessToken from '../pages/Login/ByAccessToken/LoginByAccessToken';
import CheckConsentWebhook from '../pages/Configuration/Components/AgencyAgentConfiguration/CheckConsentWebhook';

// lazy loading
const AuthRemover = lazy(() => import('../auth/AuthRemover'));
const OuterLayout = lazy(() => import('../layouts/OuterLayout'));
const AuthProvider = lazy(() => import('../auth/AuthProvider'));
const AuthLayout = lazy(() => import('../layouts/AuthLayout'));
const VerifyProvider = lazy(() => import('../auth/VerifyProvider'));

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <AuthProvider>
        <AuthLayout />
      </AuthProvider>
    ),
    errorElement: <Error500Page />,
    children: [
      {
        path: 'dashboard',
        element: (
          <Access allowedRoles={[AGENT, AGENCY_USER, CARRIER]}>
            <Dashboard />
          </Access>
        ),
        id: 'Dashboard',
      },
      {
        path: 'agency',
        element: (
          <Access allowedRoles={[AGENT]}>
            <Agency />
          </Access>
        ),
        id: 'Agency',
      },
      {
        path: 'agents',
        element: <Agents />,
        id: 'Agent',
      },

      {
        path: 'consumer',
        element: <Members />,
        id: 'Consumer',
      },
      {
        path: 'HRA',
        element: (
          <Access allowedRoles={[AGENT, AGENCY_USER, MEMBER]}>
            <HRA />
          </Access>
        ),
        id: 'HRA',
      },
      {
        path: 'view-hra',
        element: <HRAStep3 />,
        id: 'view-hra',
      },
      {
        path: 'settings',
        element: <Settings />,
        id: 'Settings',
      },
      {
        path: 'my-account',
        element: <MyAccount />,
        id: 'MyAccount',
      },
      {
        path: 'create-template',
        element: <CreateTemplate />,
        id: 'create-template',
      },

      {
        path: 'configuration',
        element: (
          <Access allowedRoles={[AGENT, AGENCY_USER, CARRIER]}>
            <Configuration />
          </Access>
        ),
        children: [
          {
            path: 'manage-keys',
            element: <GenerateKey />,
          },
          {
            path: 'email-integration',
            element: (
              <Access allowedRoles={[AGENT, AGENCY_USER]}>
                <EmailIntegration />
              </Access>
            ),
          },
          {
            path: 'storage',
            element: (
              <Access allowedRoles={[AGENT, AGENCY_USER]}>
                <Storage />
              </Access>
            ),
          },
          {
            path: 'consentWebhook',
            element: (
              <Access allowedRoles={[AGENT, AGENCY_USER]}>
                <ConsentWebhook />
              </Access>
            ),
          },
          // {
          //   path: 'checkConsent',
          //   element: (
          //     <Access allowedRoles={[AGENT, AGENCY_USER]}>
          //       <CheckConsentWebhook />
          //     </Access>
          //   ),
          // },

          //for carrier role

          {
            path: 'claim-webhook',
            element: (
              <Access allowedRoles={[CARRIER]}>
                <ClaimWebhook />
              </Access>
            ),
          },
          {
            path: 'consent-webhook',
            element: (
              <Access allowedRoles={[CARRIER]}>
                <InboundWebhook />
              </Access>
            ),
          },
          {
            path: 'consent-check',
            element: (
              <Access allowedRoles={[CARRIER]}>
                <ConsentCheck />
              </Access>
            ),
          },
          {
            path: 'consent-consumer-data',
            element: (
              <Access allowedRoles={[CARRIER]}>
                <ConsentConsumerData />
              </Access>
            ),
          },
          {
            path: 'fetch-agent',
            element: (
              <Access allowedRoles={[CARRIER]}>
                <FetchAgentRecord />
              </Access>
            ),
          },
        ],
        id: 'configuration',
      },

      {
        path: 'view-signed-pdf',
        element: <ViewSignedPDF />,
        id: 'view-signed-pdf',
      },
      {
        path: 'claims',
        element: (
          <Access allowedRoles={[AGENT, AGENCY_USER, CARRIER]}>
            <Claims />
          </Access>
        ),
        id: 'claims',
      },
      {
        path: 'invites',
        element: (
          <Access allowedRoles={[AGENT, AGENCY_USER]}>
            <Invitation />
          </Access>
        ),
        id: 'Invites',
      },
      {
        path: 'chat',
        element: (
          <Access allowedRoles={[AGENT, AGENCY_USER, CARRIER, MEMBER]}>
            <ChatPage />
          </Access>
        ),
        children: [{ path: ':chatId', element: <ChatArea /> }],
        id: 'chat',
      },

      {
        path: 'agent-kpis',
        element: (
          <Access allowedRoles={[AGENCY_USER]}>
            <AgentKpi />
          </Access>
        ),
        id: 'agentKPIs',
      },
      {
        path: 'recieve-invitation',
        element: (
          <Access allowedRoles={[AGENT]}>
            <RecieveInvitation />
          </Access>
        ),
        id: 'recieve-invitation',
      },
    ],
  },
  {
    path: '/',
    element: (
      <AuthRemover>
        <OuterLayout />
      </AuthRemover>
    ),
    errorElement: <Error500Page />,
    children: [
      {
        index: true,
        element: <Login />,
        id: 'Login',
      },

      {
        path: 'sign-up',
        element: <SignUp />,
        id: 'SignUp',
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />,
        id: 'forgot-password',
      },
      {
        path: 'plan-pricing',
        element: <PlanPricing />,
        id: 'PlanPricing',
      },
    ],
  },

  {
    path: 'verify',
    element: <VerifyProvider />,
    children: [
      {
        index: true,
        element: <Verify />,
        errorElement: <Error500Page />,
      },
    ],
  },

  {
    path: 'verify-access-token',
    element: <LoginByAccessToken />,
    id: 'verify-access-token',
    children: [
      { index: true, element: <LoginByAccessToken /> },
      { path: ':uuId', element: <LoginByAccessToken /> },
    ],
  },

  {
    path: 'view-pdf',
    element: <EligibilityPdf />,
    id: 'view-pdf',
  },
  {
    path: 'verify-identity',
    element: <Verification />,
    id: 'verify-identity',
  },
  {
    path: 'view-document',
    element: <DocumentLayout />,
    id: 'view-document',
    children: [
      { index: true, element: <PreviewDocument /> },
      { path: ':docId', element: <PreviewDocument /> },
    ],
  },
  {
    path: 'read-document',
    element: <DocumentLayout />,
    id: 'read-document',
    children: [
      { index: true, element: <PreviewDocumentReadMode /> },
      { path: ':name', element: <PreviewDocumentReadMode /> },
    ],
  },
  {
    path: 'signature-document',
    element: <DocumentLayout />,
    id: 'signature-document',
    children: [
      { index: true, element: <PreviewDocumentSignMode /> },
      { path: ':docId', element: <PreviewDocumentSignMode /> },
    ],
  },

  //HRA Agreement
  {
    path: 'hra-agreement-sign-doc',
    element: <DocumentLayout />,
    id: 'hra-agreement-sign-doc',
    children: [
      { index: true, element: <HRAAggrementSignMode /> },
      { path: ':docId', element: <HRAAggrementSignMode /> },
    ],
  },
  {
    path: 'hra-agreement-view-doc',
    element: <DocumentLayout />,
    id: 'hra-agreement-view-doc',
    children: [
      { index: true, element: <HRAAgreementPreviewDocument /> },
      { path: ':docId', element: <HRAAgreementPreviewDocument /> },
    ],
  },

  //HRA CONSENT
  {
    path: 'hra-consent-sign-doc',
    element: <DocumentLayout />,
    id: 'hra-consent-sign-doc',
    children: [
      { index: true, element: <HRAConsentSignMode /> },
      { path: ':docId', element: <HRAConsentSignMode /> },
    ],
  },
  {
    path: 'hra-consent-view-doc',
    element: <DocumentLayout />,
    id: 'hra-consent-view-sign-doc',
    children: [
      { index: true, element: <HRAConsentPreviewDocument /> },
      { path: ':docId', element: <HRAConsentPreviewDocument /> },
    ],
  },
  //HRA FORM AGREEMENT
  {
    path: 'hra-form-sign-doc',
    element: <DocumentLayout />,
    id: 'hra-form-sign-doc',
    children: [
      { index: true, element: <HRAFormSignMode /> },
      { path: ':docId', element: <HRAFormSignMode /> },
    ],
  },
  {
    path: 'hra-form-view-doc',
    element: <DocumentLayout />,
    id: 'hra-form-view-sign-doc',
    children: [
      { index: true, element: <HRAFormPreviewDocument /> },
      { path: ':docId', element: <HRAFormPreviewDocument /> },
    ],
  },

  //outer sign
  {
    path: 'signature-doc',
    element: <DocumentLayout />,
    id: 'signature-doc',
    children: [
      { index: true, element: <PreviewDocumentSignModeOuter /> },
      { path: ':docId', element: <PreviewDocumentSignModeOuter /> },
    ],
  },
  {
    path: 'view-signature-document',
    element: <DocumentLayout />,
    id: 'view-signature-document',
    children: [
      { index: true, element: <PreviewDocumentSignReadMode /> },
      { path: ':docId', element: <PreviewDocumentSignReadMode /> },
    ],
  },

  {
    path: '/unauthorized',
    element: <Unauthorized />,
  },
  {
    path: '*',
    element: <PageNotFound />,
  },
]);
