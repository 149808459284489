import React, { useRef } from 'react';
import SignaturePad from 'react-signature-canvas';
import Button from '../../Button';
import { toast } from 'react-toastify';

const Manual = ({ handleChange = () => {} }) => {
  const sigCanvas = useRef({});

  function clear() {
    sigCanvas.current.clear();
  }

  function save() {
    if (sigCanvas.current) {
      if (sigCanvas.current?.isEmpty()) {
        return toast.error('Please Sign the document.');
      }
      const imageBase64 = sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL('image/png');
      handleChange(null, imageBase64);
    }
  }
  return (
    <div className="d--flex flex--column w--full gap--md">
      <SignaturePad
        ref={sigCanvas}
        canvasProps={{
          className: 'signatureCanvas w--full bg--white',
        }}
      />
      <div className="d--flex justify-content--center w--full gap--sm">
        <Button
          type="button"
          onClick={clear}
          variant="primary-100"
          color="black"
          isOutline
          borderColor="primary"
          btnClasses="btn w-max--150"
          data-link-hover
          size="sm"
        >
          Clear
        </Button>
        <Button
          type="button"
          onClick={save}
          variant="primary"
          color="black"
          isOutline
          borderColor="primary"
          btnClasses="btn w-max--150"
          size="sm"
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default Manual;
